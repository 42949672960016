import { format } from 'date-fns'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store'
import { fetchFeedback, setActivePageIndex, setShowRowsCount } from '../../../../../store/slices/feedback'
import { Pagination } from '../../../../complexes/Pagination'

import styles from "./Cards.module.scss"
import Row from './Row'

// const mockData: FeedbackDTO[] = [{
//   id: "id1",
//   consumer_name: 'consumer_name1',
//   // consumer_avatar: 'consumer_avatar1',
//   rating: 3,
//   product_id: 1,
//   color_id: 1,
//   size_id: 1,
//   product_name: 'product_name1',
//   product_url: "string1",
//   feedback_text: 'feedback_text1',
//   feedback_date: 1679000436066,
//   company_name: 'company_name',
//   // company_avatar: 'company_avatar1',
//   has_answer: false,
//   on_moderation: false,
//   moderation_failed: false,
//   is_editable: true,
//   answer_text: '',
//   answer_date: 1679000436066
// }, {
//   id: "id2",
//   consumer_name: 'consumer_name2',
//   // consumer_avatar: 'consumer_avatar2',
//   rating: 5,
//   product_id: 2,
//   color_id: 2,
//   size_id: 2,
//   product_name: 'product_name2',
//   product_url: "string2",
//   feedback_text: 'feedback_text2',
//   feedback_date: 1673000436066,
//   company_name: 'company_name',
//   // company_avatar: 'company_avatar2',
//   has_answer: false,
//   on_moderation: false,
//   moderation_failed: false,
//   is_editable: true,
//   answer_text: '',
//   answer_date: 1679000436066
// }, {
//   id: "id3",
//   consumer_name: 'consumer_name2',
//   consumer_avatar: 'consumer_avatar2',
//   rating: 5,
//   product_id: 2,
//   color_id: 2,
//   size_id: 2,
//   product_name: 'product_name2',
//   product_url: "string2",
//   feedback_text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
//   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
//   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
//   `,
//   feedback_date: 1679000136066,
//   company_name: 'company_name',
//   company_avatar: 'company_avatar2',
//   has_answer: true,
//   on_moderation: false,
//   moderation_failed: false,
//   is_editable: true,
//   answer_text: '',
//   answer_date: 1619000436066
// }, {
//   id: "id4",
//   consumer_name: 'consumer_name2',
//   consumer_avatar: 'consumer_avatar2',
//   rating: 5,
//   product_id: 2,
//   color_id: 2,
//   size_id: 2,
//   product_name: 'product_name2',
//   product_url: "string2",
//   feedback_text: 'feedback_text2',
//   feedback_date: 1679000436066,
//   company_name: 'company_name',
//   company_avatar: 'company_avatar2',
//   has_answer: true,
//   on_moderation: false,
//   moderation_failed: true,
//   is_editable: true,
//   answer_text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
//   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
//   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
//   `,
//   answer_date: 1679000436066
// }]

export default function Cards() {
  const dispatch = useAppDispatch()
  const isQuestions = useAppSelector(state => state.feedback.isQuestions)
  const feedbackData = useAppSelector(state => state.feedback.feedback)
  const feedbackTotalCount = useAppSelector(state => state.feedback.feedbackTotalCount)
  const activePageIndex = useAppSelector(state => state.feedback.activePageIndex)
  const showRowsCount = useAppSelector(state => state.feedback.showRowsCount)
  const updateTime = useAppSelector(state => state.feedback.updateTime)

  useEffect(() => {
    dispatch(fetchFeedback())
  }, [activePageIndex, showRowsCount])

  const pagesCount = Math.ceil(feedbackTotalCount / showRowsCount)

  const handleSelectPage = (index: number) => {
    dispatch(setActivePageIndex(index))
  }
  const handleSelectRowsLimit = (count: number) => {
    dispatch(setShowRowsCount(count))
  }

  return (
    <div className={styles.cards}>
      <div className={styles.grid}>
        <div className={styles.grid_head}>
          <div className={styles.grid_row}>
            <div className={styles.grid_cel}>
              <span className={styles.title}>{isQuestions?"Отзывы":"ВОПРОСЫ"}</span>
              <span className={styles.update_time}>Время обновления: {format(updateTime, "dd.MM.yyyy в HH:mm")}</span>
            </div>
            <div className={styles.grid_cel}>
              <span className={styles.title}>Ответы</span>
            </div>
          </div>
        </div>
        <div className={styles.grid_body_wrap}>
          <div className={styles.grid_body}>
            {(feedbackData.length < 1) && <div className={styles.gag}>Нет записей</div>}
            {feedbackData.map(f => <Row key={f.id} feedbackData={f} />)}
          </div>
        </div>
      </div>
      <div className={styles.pagination}>
        <Pagination pagesCount={pagesCount} showRowsCount={showRowsCount} activePageIndex={activePageIndex} onSelectPage={handleSelectPage} onSelectRowsLimit={handleSelectRowsLimit} />
      </div>
    </div>
  )
}
