import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/store';
import { FeedbackSorting, setSorting } from '../../../../../store/slices/feedback';
import { Select } from '../../../../simples/Select'
import styles from "./Reviews.module.scss";
import { CustomSelect_v2 } from '../../../../simples/CustomSelect_v2';

const options = [
  {
    label: "Дата по возрастанию",
    value: FeedbackSorting.OLD_TO_NEW
  },
  {
    label: "Дата по убыванию",
    value: FeedbackSorting.NEW_TO_OLD
  },
  {
    label: "Сначала положительные",
    value: FeedbackSorting.GOOD_TO_BAD
  },
  {
    label: "Сначала отрицательные",
    value: FeedbackSorting.BAD_TO_GOOD
  },
]


export default function SortSelect() {

  const dispatch = useAppDispatch()
  const value = useAppSelector(state => state.feedback.sorting)

  const handleChange = ({ value }) => {
    dispatch(setSorting(value))
  }

  return (
    <CustomSelect_v2 className={styles.sorting_select} name='sorting' options={options} value={value} onChange={handleChange} hideError />
    // <Select className={styles.sorting_select} name='sorting' options={options} value={value} onChange={handleChange} />
  )
}
