import React, { useRef, useState } from 'react'
import { FieldTitle } from '../../../../simples/FieldTitle'
import { CustomHint } from '../../../../simples/CustomHint'

import styles from './VariantPhotos.module.scss'
import classNames from 'classnames'
import Photo from './Photo'
import CustonDropzone from './CustonDropzone'
import { ProductPhoto, removePhotos } from '../../../../../store/slices/product'

import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import { SortableContext, arraySwap, rectSwappingStrategy } from '@dnd-kit/sortable';
import { BlueButton } from '../../../../simples/BlueButton'
import { useAppDispatch } from '../../../../../hooks/store'

type Props = {
  title: string
  formats: string[]
  emptyDropzoneContent: JSX.Element
  // photos: string[]
  photos: ProductPhoto[]
  onRemove: (id: number) => void
  onDrop: (file: File[]) => void
  multiple?: boolean
  onSort?: (oldIndex: number, newIndex: number) => void
  onSetMain?: (id: number) => void
  // mainPhotoId?: number
  // hideHint?: boolean
}

export default function Photos({ title, formats, onRemove, photos, onDrop, multiple, onSort, onSetMain, emptyDropzoneContent }: Props) {

  const [checkedIDs, setCheckedIDs] = useState<number[]>([])
  const dispatch = useAppDispatch()

  const hint = <div>Добавьте фото <br />для образа <br />согласно <br /><a>инструкции</a></div>

  type DropzoneHandle = React.ElementRef<typeof CustonDropzone>;
  const drop = useRef<DropzoneHandle>(null);

  const handleOpenDialog = () => {
    drop.current?.open_dialog()
  }


  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 4,
      }
    }),
    useSensor(TouchSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    if (!onSort) return
    const { active, over } = event;
    // console.log({ active, over });

    if (active.id !== over?.id) {
      const oldIndex = photos.findIndex(p => p.id === active.id);
      const newIndex = photos.findIndex(p => p.id === over!.id);
      // dispatch(movePhotoAC({ oldIndex, newIndex }))
      // const _photos = arraySwap(photos, oldIndex, newIndex)
      // onSort(_photos)
      onSort(oldIndex, newIndex)
    }
  };

  const handleSetMain = (id: number) => () => {
    onSetMain && onSetMain(id)
  }
  const handleRemove = () => {
    dispatch(removePhotos(checkedIDs))
    setCheckedIDs([])
  }

  const handleChangeCheck = (id: number) => (checked: boolean) => {
    if (checked) {
      setCheckedIDs([...checkedIDs, id])
    } else {
      setCheckedIDs(checkedIDs.filter(cid => cid !== id))
    }
  }

  let content
  if (photos.length === 0) {
    // content = <EmptyArea formats={formats} multiple={multiple} onDrop={onDrop} dropzoneContent={emptyDropzoneContent} ref={drop} />
    content = <CustonDropzone formats={formats} multiple={multiple} onDrop={onDrop} dropzoneContent={emptyDropzoneContent} ref={drop} isEmpty={true} className='' />
  } else {
    content = <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      // onDragStart={handleDragStart}
      // onDragCancel={handleDragCancel}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={photos.map(p => p.id)} strategy={rectSwappingStrategy} disabled={photos.length < 2}>
        <div className={classNames(styles.photos)}>
          {photos.map((p, i) => <Photo key={p.id}
            photo={p} onRemove={onRemove}
            setMain={multiple ? handleSetMain(p.id) : undefined}
            // isMain={p.isMain}
            isChecked={checkedIDs.includes(p.id)}
            onChange={multiple ? handleChangeCheck(p.id) : undefined}
          />)}
        </div>
      </SortableContext>
    </DndContext>
  }


  return (
    <div className={styles.variant_photos}>
      <FieldTitle text={title} required />
      <div className={classNames(styles.photos_wrap, {
        [styles.has_photo]: photos.length === 1,
        [styles.has_photos]: photos.length > 1
      })}>
        {content}
        {!multiple && <CustomHint hint={hint} hintWidth={190} />}
      </div>
      {(!multiple || photos.length === 0) && <>
        {photos.length === 0 && <div className={styles.upload_btn} onClick={handleOpenDialog} >Загрузить фото</div>}
        <div className={styles.upload_info}>Размер изображения не должен превышать 5Mb <br />Формат фотографий {formats.join(' или ')}</div>
        {photos.length !== 0 && <div className={styles.upload_btn} onClick={handleOpenDialog} >Заменить фото</div>}
      </>}

      {/* {(multiple && photos.length !== 0) && <UploadArea formats={formats} multiple={multiple} onDrop={onDrop} ref={drop} />} */}
      {(multiple && photos.length > 0 && photos.length < 10) && <CustonDropzone formats={formats} multiple={multiple} onDrop={onDrop} ref={drop} isEmpty={false} className='' dropzoneContent='' />}
      {multiple && photos.length > 0 && <div className={styles.remove_controls}>
        <BlueButton title='Удалить' onClick={handleRemove} disabled={checkedIDs.length === 0} />
        {(photos.length > 10) && <CustomHint hintWidth={250} hint={<>
          <div>Загружено максимальное количество фотографий.</div>
          <div><b>Удалите 1 или более для загрузки новых фотографий</b></div>
        </>} />}
      </div>}

    </div >
  )
}
