import React, { useState } from 'react'
import api from "../../../../../utils/api";
import { GoodsAnswer, ListDataType, ListHeadData, ListProductDataType } from '../../../../../types/productListTypes';
import { ModalProductSearch } from '../../../../complexes/ModalProductSearch';
import { AvailableProductData } from '../../../../complexes/ProductList';
import ProductList from '../../../../complexes/ProductList/ProductList';
import { BlueButton, ButtonSize, ButtonType, IconPosition } from '../../../../simples/BlueButton';
import Discount, { DiscountType } from '../Discount/Discount';
import { DatePickerDate } from '../../../../complexes/CustomDatePicker';
import { Endpoints } from '../../../../../constants/server';
import { dateToDatePickerDate, datePickerDateToDate } from '../../../../complexes/CustomDatePicker/CustomDatePicker';
import ModalNotification from '../ModalNotification/ModalNotification';
import { DiscountBannerBuilder } from '../DiscountBannerBuilder';
import { format } from 'date-fns';

import styles from "./DiscountTab.module.scss"

// import trash_icon from "../../../../../assets/icons/trash_icon.svg";
import banner21_icon from "../../../../../assets/icons/banner21.svg";
import plus_icon from "../../../../../assets/icons/plus.svg";
import { Checkbox } from '../../../../simples/Checkbox';
import { ModalDialog } from '../../../../simples/ModalDialog';
import { IHint } from '../../../../simples/IHint';
import { ModalServerError, ServerError } from '../../../../complexes/ModalServerError';
import { BannerConfig } from '../../../../../types/discount';
import { DefaultBannerConfig } from '../../../../../constants/discount';
import { ProductsDTO } from '../../../../../types/swagger/llyApi_new';
import { DefaultHeaders } from '../../../../complexes/ProductList/DefaultHeaders';


type CreateDiscountResponse = {
  data?: ProductsDTO[]
  status: number
  success: boolean
  errors?: any
}

const headers: ListHeadData[] = [
  DefaultHeaders.photo,
  DefaultHeaders.barcode,
  DefaultHeaders.name,

  DefaultHeaders.retail_price,
  DefaultHeaders.discountValue,
  DefaultHeaders.discountPercent,
  DefaultHeaders.priceWithDiscount,

  DefaultHeaders.size,
  DefaultHeaders.color,

  DefaultHeaders.fbo,
  DefaultHeaders.fbs,
  DefaultHeaders.discountItemsCount,

  DefaultHeaders.brand,
  DefaultHeaders.sex,
  DefaultHeaders.type,
  DefaultHeaders.category,
  DefaultHeaders.subcategory,
  DefaultHeaders.season,
  DefaultHeaders.dateOfCreation,
];
const headers21: ListHeadData[] = [
  DefaultHeaders.photo,
  DefaultHeaders.barcode,
  DefaultHeaders.name,
  DefaultHeaders.retail_price,
  DefaultHeaders.discountValue,
  DefaultHeaders.stockBalance,
  DefaultHeaders.brand,
  DefaultHeaders.sex,
  DefaultHeaders.category,
  DefaultHeaders.subcategory,
  DefaultHeaders.size,
  DefaultHeaders.season,
  DefaultHeaders.dateOfCreation,
];

const formatDateForServer = (d?: DatePickerDate) => {
  if (!d) return ""
  return format(datePickerDateToDate(d), "yyyy-MM-dd")
}

const defaultDateFrom = dateToDatePickerDate(new Date())
const defaultDateTo = dateToDatePickerDate((() => {
  const _d = new Date()
  _d.setDate(_d.getDate() + 1)
  return _d
})())

export default function DiscountTab() {
  const [products, setProducts] = useState<ProductsDTO[]>([]);
  const [productsSearchMode, setProductsSearchrMode] = useState(false);

  const [count, setCount] = useState(0);
  const [discount, setDiscount] = useState(0);

  const [dateFrom, setDateFrom] = useState<DatePickerDate>(defaultDateFrom);
  const [dateTo, setDateTo] = useState<DatePickerDate>(defaultDateTo);
  const [valueInPercent, setValueInPercent] = useState(false);
  const [discountInPercent, setDiscountInPercent] = useState(true);
  const [isStandardDiscount, setIsStandardDiscount] = useState(true);

  const [bannerConfig, setBannerConfig] = useState<BannerConfig>(DefaultBannerConfig);
  const [bannerConfigOnEditing, setBannerConfigOnEditing] = useState<BannerConfig | undefined>();

  const [notificationText, setNotificationText] = useState("")
  const [serverError, setServerError] = useState<ServerError>()

  const [discountPreviewProductIndex, setDiscountPreviewProductIndex] = useState<number>(0)

  const [showAllCols, setShowAllCols] = useState(false)
  const [showCancelDiscountDialog, setShowCancelDiscountDialog] = useState(false)


  const [products21_1, setProducts21_1] = useState<ProductsDTO[]>([]);
  const [products21_2, setProducts21_2] = useState<ProductsDTO[]>([]);
  const [products21_3, setProducts21_3] = useState<ProductsDTO[]>([]);

  const [products21SearchMode, setProducts21SearchrMode] = useState(0); // 0 - off, 1 - products21_1, 2 - products21_2, 3 - products21_3


  const loadProducts = async (ids: string[]) => {
    let data: ProductsDTO[] = []
    if (ids.length > 0) {
      const req = `${Endpoints.GET_SELECTED_PRODUCTS}`;
      await api
        .post<GoodsAnswer>(req, { products: ids })
        .then((res) => {
          data = res.data?.data || [];
        })
        .catch((err) => console.log(err));
    }
    return data
  }

  const handleSwToNextPreviewProduct = () => {
    let nextIndex = discountPreviewProductIndex + 1
    if (nextIndex >= products.length) {
      nextIndex = 0
    }
    setDiscountPreviewProductIndex(nextIndex)
  }

  const handleSwToPrevPreviewProduct = () => {
    let nextIndex = discountPreviewProductIndex - 1
    if (nextIndex < 0) {
      nextIndex = products.length - 1
    }
    setDiscountPreviewProductIndex(nextIndex)
  }

  const handleCloseModalNotification = () => {
    setNotificationText("")
  }

  const handleCloseDiscountBannerBuilder = (save: boolean) => {
    if (save) { setBannerConfig(bannerConfigOnEditing || DefaultBannerConfig) }
    setBannerConfigOnEditing(undefined)
  }

  const handleCloseCancelDisountDialog = () => {
    setShowCancelDiscountDialog(false)
  }

  const handleRemoveDisount = () => {
    setShowCancelDiscountDialog(false)
    const ids = products.map(p => p.id)
    api.post<CreateDiscountResponse>(Endpoints.REMOVE_DISCOUNT, { ids })
      .then(async (res) => {
        setNotificationText(res?.data?.data?.toString() || "")
        const data = await loadProducts(ids)
        setProducts(data);
      })
      .catch((err) => {
        const errorText = err?.response?.data?.errors || err.toString()
        setNotificationText(JSON.stringify(errorText))
      })
  }

  const discountBannerPreviewProduct = products[discountPreviewProductIndex]


  let content: JSX.Element;

  if (isStandardDiscount) {

    const handleCreateDisount = () => {
      const banner_json = JSON.stringify(bannerConfig)
      const d = {
        "start_date": formatDateForServer(dateFrom),
        "finish_date": formatDateForServer(dateTo),
        "count": count,
        "countInPercent": valueInPercent,
        "value": discount,
        "valueInPercent": discountInPercent,
        "productsIds0": products.map(p => p.id),
        "productsIds1": [],
        "productsIds2": [],
        banner_json,
        isStandardDiscount
      }

      if (products.length > 0) {
        setDiscountPreviewProductIndex(0)
      }

      api.post<CreateDiscountResponse>(Endpoints.CREATE_DISCOUNT, d)
        .then((res) => {
          // if (res.data.data) {
          //   setProducts(res.data.data);
          if (res.data.success) {
            setNotificationText("Скидка успешно создана.")
            loadProducts(products.map(p => p.id)).then(data => {
              setProducts(data);
            })
          } else {
            // setNotificationText(JSON.stringify(res.data.errors))
            setServerError(res.data.errors)
          }
        })
        .catch((err) => {
          // const errorText = err?.response?.data?.errors || err.toString()
          // setNotificationText(JSON.stringify(errorText))
          if (err?.response?.data?.errors) {
            setServerError(err?.response?.data?.errors)
          } else {
            setNotificationText("Ошибка на стороне сервера")
          }
        })
    }

    const handleShowCancelDisountDialog = () => {
      setShowCancelDiscountDialog(true)
    }

    const handleSelectProducts = () => {
      setProductsSearchrMode(true);
    }

    const handleEditBanner = () => {
      setBannerConfigOnEditing(JSON.parse(JSON.stringify(bannerConfig || DefaultBannerConfig)))
    }

    const handleRemoveItemsFromList = (ids: string[]) => {
      setProducts(products.filter(p => ids.indexOf(p.id) === -1))
    }

    const productsWithDiscount = products.filter(p => p.hasDiscount).map(p => p.id)

    // let linkText = "товаров"
    // if (products.length === 1) { linkText = "товар" }
    // if (products.length > 1 && products.length < 5) { linkText = "товара" }
    // linkText = `${products.length} ${linkText}`

    let linkText = "товаров"
    let _v = products.length
    if (_v > 15) _v %= 10
    if (_v === 1) { linkText = "товар" }
    if (_v > 1 && _v < 5) { linkText = "товара" }
    linkText = `${products.length} ${linkText}`

    const handleCloseSearch = async (values: string[]) => {
      setProductsSearchrMode(false);
      const data = await loadProducts(values)
      setProducts(data);
    }



    const saveIsDisabled = products.length === 0 || discount === 0 || Number.isNaN(discount)

    content = <>
      <ModalProductSearch onClose={handleCloseSearch} isVisible={productsSearchMode} />
      <div className={styles.controls1}>
        <BlueButton className={styles.select_product_btn} onClick={handleSelectProducts} title="Добавить карточки для применения скидки" />
        <Checkbox label='Показать все поля' checked={showAllCols} onChange={setShowAllCols} />
      </div>

      <span className={styles.count_info}>Всего участвует в скидке <span className={styles.link} onClick={handleSelectProducts}>{linkText}</span></span>
      <ProductList
        data={products} headers={headers} errors={productsWithDiscount}
        discount={{ count, discountInPercent, discount, valueInPercent }}
        className={styles.products_list}
        isRemovable={true}
        removeItemsFromList={handleRemoveItemsFromList}
      />
      <div className={styles.footer}>
        <div className={styles.btns_group}>
          <BlueButton disabled={products.length === 0} onClick={handleEditBanner} title='Установить скидочный баннер' />
          <BlueButton disabled={productsWithDiscount.length === 0} onClick={handleShowCancelDisountDialog} title='Отменить скидки на все карточки' />
        </div>
        <BlueButton onClick={handleCreateDisount} disabled={saveIsDisabled} title='Сохранить' />
      </div>
    </>
  } else {

    const handleRemoveItemsFromList1 = (ids: string[]) => {
      setProducts21_1(products21_1.filter(p => ids.indexOf(p.id) === -1))
    }
    const handleRemoveItemsFromList2 = (ids: string[]) => {
      setProducts21_2(products21_2.filter(p => ids.indexOf(p.id) === -1))
    }
    const handleRemoveItemsFromList3 = (ids: string[]) => {
      setProducts21_3(products21_3.filter(p => ids.indexOf(p.id) === -1))
    }

    const handleSelectProducts21_1 = () => {
      setProducts21SearchrMode(1);
    }
    const handleSelectProducts21_2 = () => {
      setProducts21SearchrMode(2);
    }
    const handleSelectProducts21_3 = () => {
      setProducts21SearchrMode(3);
    }



    const handleCreateDisount21 = () => {
      const d = {
        "start_date": formatDateForServer(dateFrom),
        "finish_date": formatDateForServer(dateTo),
        "count": count,
        "countInPercent": valueInPercent,
        // "value": discount,
        // "valueInPercent": discountInPercent,
        "productsIds0": products21_1.map(p => p.id),
        "productsIds1": products21_2.map(p => p.id),
        "productsIds2": products21_3.map(p => p.id),
        isStandardDiscount
      }
      if (products.length > 0) {
        setDiscountPreviewProductIndex(0)
      }

      api.post<CreateDiscountResponse>(Endpoints.CREATE_DISCOUNT, d)
        .then((res) => {
          // if (res.data.data) {
          //   setProducts(res.data.data);
          // }else{
          // }
          setProducts21_1([])
          setProducts21_2([])
          setProducts21_3([])

          if (res.data.success) {
            setNotificationText("Скидка успешно создана.")
          } else {
            setNotificationText(JSON.stringify(res.data.errors))
          }
        })
        .catch((err) => {
          // const errorText = err?.response?.data?.errors || err.toString()
          // setNotificationText(JSON.stringify(errorText))
          if (err?.response?.data?.errors) {
            setServerError(err?.response?.data?.errors)
          } else {
            setNotificationText("Ошибка на стороне сервера")
          }
        })
    }
    const handleShowCancelDisountDialog = () => {
      setShowCancelDiscountDialog(true)
    }

    const handleCloseSearch21 = async (values: string[]) => {
      setProducts21SearchrMode(0);
      const data = await loadProducts(values)
      switch (products21SearchMode) {
        case 1:
          setProducts21_1(data)
          break;
        case 2:
          setProducts21_2(data)
          break;
        case 3:
          setProducts21_3(data)
          break;
        default:
          break;
      }
    }

    const productsWithDiscount1 = products21_1.filter(p => p.hasDiscount).map(p => p.id)
    const productsWithDiscount2 = products21_2.filter(p => p.hasDiscount).map(p => p.id)
    const productsWithDiscount3 = products21_3.filter(p => p.hasDiscount).map(p => p.id)

    const productsWithDiscount = [
      ...productsWithDiscount1,
      ...productsWithDiscount2,
      ...productsWithDiscount3
    ]

    const saveIsDisabled = products21_1.length === 0 || products21_2.length === 0 || products21_3.length === 0

    content = <>
      <ModalProductSearch onClose={handleCloseSearch21} isVisible={products21SearchMode > 0} />
      <div className={styles.banner21_info}>
        <div className={styles.banner21_hint}>
          <IHint className={styles.hint21} hint='Добавьте карточки в разделы: Товар1 , Товар2 и Товар3 (бесплатный) согласно акции 2+1' />
        </div>
        <div className={styles.banner21}>
          <img src={banner21_icon} alt="" />
          <span>На товары будет установлен скидочный баннер</span>
        </div>
      </div>
      <div>
        <div className={styles.d21TableTitle}>
          <span>Товар 1</span><img src={plus_icon} onClick={handleSelectProducts21_1} />
        </div>
        <ProductList
          data={products21_1} headers={headers21} errors={productsWithDiscount1}
          discount={{ count, discountInPercent, discount, valueInPercent }}
          className={styles.products_list}
          isRemovable={true}
          removeItemsFromList={handleRemoveItemsFromList1}
        />
      </div>
      <div>
        <div className={styles.d21TableTitle}>
          <span>Товар 2</span><img src={plus_icon} onClick={handleSelectProducts21_2} />
        </div>
        <ProductList
          data={products21_2} headers={headers21} errors={productsWithDiscount2}
          discount={{ count, discountInPercent, discount, valueInPercent }}
          className={styles.products_list}
          isRemovable={true}
          removeItemsFromList={handleRemoveItemsFromList2}
        />
      </div>
      <div>
        <div className={styles.d21TableTitle}>
          <span>Товар 3 (бесплатный)</span><img src={plus_icon} onClick={handleSelectProducts21_3} />
        </div>
        <ProductList
          data={products21_3} headers={headers21} errors={productsWithDiscount3}
          discount={{ count, discountInPercent, discount, valueInPercent }}
          className={styles.products_list}
          isRemovable={true}
          removeItemsFromList={handleRemoveItemsFromList3}
        />
      </div>
      <div className={styles.d21controls}>
        <BlueButton disabled={productsWithDiscount.length === 0} onClick={handleShowCancelDisountDialog} title='Отменить скидки на все карточки' />
        <BlueButton disabled={saveIsDisabled} title='Сохранить' onClick={handleCreateDisount21} />
      </div>
    </>
  }

  const handleClearServerError = () => {
    setServerError(undefined)
  }

  return (
    <div>
      <ModalNotification text={notificationText} onClose={handleCloseModalNotification} />
      <ModalServerError onClose={handleClearServerError} error={serverError} />
      <ModalDialog isActive={showCancelDiscountDialog}
        text="Отменить скидки на товары в таблице?"
        buttonsConfig={[{
          text: "Да",
          type: ButtonType.Yes,
          onClick: handleRemoveDisount,
          size: ButtonSize.Big
        }, {
          text: "Нет",
          type: ButtonType.Cancel,
          onClick: handleCloseCancelDisountDialog,
          size: ButtonSize.Big
        }]}
        handleClose={handleCloseCancelDisountDialog} />
      <DiscountBannerBuilder
        discountInPercent={discountInPercent}
        config={bannerConfigOnEditing}
        onChange={setBannerConfigOnEditing}
        onClose={handleCloseDiscountBannerBuilder}
        discountValue={discount}
        product={discountBannerPreviewProduct}
        swToNextProduct={handleSwToPrevPreviewProduct}
        swToPrevProduct={handleSwToNextPreviewProduct}
      />
      <Discount
        discountInPercent={discountInPercent}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        setDiscountInPercent={setDiscountInPercent}
        setValueInPercent={setValueInPercent}
        valueInPercent={valueInPercent}
        dateFrom={dateFrom}
        dateTo={dateTo}
        isStandardDiscount={isStandardDiscount}
        setIsStandardDiscount={setIsStandardDiscount}
        count={count}
        setCount={setCount}
        discount={discount}
        setDiscount={setDiscount}
      />
      {content}
    </div>
  )
}
